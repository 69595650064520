<template>
  <div>
    <div>

      <el-row class="cc">
        <el-col :span="16" :offset="4">
          <div class="top_dh">
            <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(1)">磐石产品</span> &gt; <span @click="changeNav(1)">磐石一体机</span> &gt; <span>磐石一体机</span></p>
          </div>
          <p class="IntroductionTitle">概述</p>
        </el-col>
      </el-row>

      <el-row class="Introduction cw">
        <el-col :span="16" :offset="4" style="margin-bottom: 20px;">
          <div class="contectBox">
            <div class="contectimg">
              <img src="@/assets/aqwg/aq3.png" />
            </div>
            <div class="IntroductionDesc" style="overflow:hidden;">
              <h6>磐石一体机</h6>
              <br>
              <p :style="{height:descShow ? 'auto' : '168px'}">
                磐石一体机面向工作组级安全桌面用户，运用超融合技术将计算资源、存储资源和网络资源实现一体化管理，采用模块化设计，支持弹性发布，降低实施成本，开机即用。
<br/>
              <br/>
              &emsp;&emsp;
磐石一体机实现了资源模块化的横向弹性伸缩，形成统一的计算与存储资源池，不仅可以减少数据中心服务器数量，整合IT资源，达到提高资源利用率和降低整体拥有成本的目的；而且可以利用软件定义数据中心技术，建立一个安全的、资源可按需调配的数据中心环境，为业务部门提供成本更低、服务水平更高的IT基础架构，从而能够针对业务部门的需求做出快速的响应。
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <!--    产品架构-->
      <el-row class="cc">
        <el-col :span="16" :offset="4">
          <p class="IntroductionTitle mt">产品介绍</p>
        </el-col>
        <el-col :span="16" :offset="4" class="sts">
          <p style="text-align:justify;font-size: 16px;color: #888;line-height: 28px;width: 80%;margin-left:10%;display: block;text-indent: 2em;">
          企业级私有云基于超融合架构，仅需通用的X86服务器和交换机硬件，利用软件定义技术实现计算、存储、网络完全资源池化，即可替代繁重复杂的传统工厂业务架构，实现云架构应用。解决数据中心投入成本高、运维工作量大、扩展性差等问题，提高工厂投资经济效益，提升运维和管理工作效率，并且实现“开箱即用”的便利性。该产品系面向组级用户提供的快捷部署方案，可以有效满足50~100用户规模的应用场景。
          </p>
          <div class="stt">
            <img src="@/assets/aqwg/ytj.png" />
            <!-- <img src="@/assets/aqwg/aq34.png"> -->
          </div>
        </el-col>
      </el-row>
  <!--产品功能-->
      <el-row class="cw">
          <el-col :span="16" :offset="4" style="padding-bottom:2%;">
            <p class="IntroductionTitle mt">产品特色</p>
            <div style="margin-top:2%;">
              <div v-for="(item,index) in proFunction" :key="index" class="proFunctionBox">
                <div class="proFunctionImg"><img v-lazy="item.imgSrc" width="90%"></div>
                <div class="proFunctionTitle">{{item.title}}</div>
                <div class="proFunctionDesc"><p>{{item.desc}}</p></div>
              </div>
            </div>
          </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "cloudDesktop",
  data(){
    return{
      proFunction:[
        {title:'开箱即用',desc:'按照用户实际应用场景，以及资源需求规模，实现开箱即用，简化部署流程。',imgSrc:require('@/assets/aqwg/a20.png'),},
        {title:'按需定制',desc:'根据客户资源需求，进行合理配置和部署，既保障业务正常使用，同时避免盲目资源投入，节省用户投入。',imgSrc:require('@/assets/aqwg/a21.png'),},
        {title:'方便扩容',desc:'根据用户规模，系统平台提供充足的扩容通道，实现对用户资源平滑升级，节约用户投资。',imgSrc:require('@/assets/aqwg/a23.png'),},
        {title:'信息安全',desc:'有效提升数据安全性，既规避数据被篡改的可能，又提供完整的数据备份，确保生产数据得到备份，保障数据安全。',imgSrc:require('@/assets/aqwg/a24.png'),},
        {title:'定义终端',desc:'通过软件定义终端的模式，对终端新构，利用现有电脑安装部署专门的终端软件实现桌面终端的重新利用。',imgSrc:require('@/assets/aqwg/a19.png'),},
        {title:'简化运维',desc:'改变传统PC一对一的维护模式，只需维护好一体机组件服务器，即可以保障业务正常运行。',imgSrc:require('@/assets/aqwg/a22.png'),},
      ],
      yingyongcj:[
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx1.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx2.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx3.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx4.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx5.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx6.png')}
      ],
      descShow:true,
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('psytjMobile')
    }
  },
  methods:{
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changeNav(navNum){
      this.$store.dispatch('changeShow',navNum)
    },
  }
}
</script>

<style scoped>

/* 通用样式 */
li{
  list-style: none;
}
.clearfix{
  clear: both;
}
/* 导航栏 */
.top_dh{
  margin-bottom: 20px;
}
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
.aqa{
  padding-bottom: 20px;
}
.aqa p{
  font-size:16px;
  color:#888;
  text-indent: 2em;
  text-align: justify;
  display: block;
  width: 85%;
  margin: 20px auto;
  line-height: 28px;
}
.aqa img{
  width: 80%;
  display: block;
  margin: 0 auto;
}
.contectimg{
  width: 39.5%;
  float: left;
  margin-right: 5%;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  padding-top: 11px;
}
.contectimg img{
  width: 100%;
}
/* 页面样式 */
/*产品概述*/
.cc{background: #f5f5f5;padding-bottom: 30px;}
.cw{background: #fff;}
.rightbtn{text-align: right;}
.mt{margin-top: 3%;}
.contectBox{
  margin-top: 30px;
}
.Introduction{
  position: relative;
}
.IntroductionTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
}
.IntroductionDesc{
  font-size: 18px;
  color: #888;
  line-height: 31px;
}
.IntroductionDesc h6{
  font-size: 26px;
  line-height: 40px;
  font-weight: bold;
  color: #000;
}
.sts{padding-top: 20px;}
.sts p{margin-bottom: 20px;}
.stt img{width: 80%;display: block;margin:0 auto;}
.IntroductionDesc p{
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
  text-align: justify;
}
/*产品架构区块*/
.currencyTitle{
  font-size: 48px;
  font-weight: bold;
  color: #101010;
  text-align: center;
  letter-spacing: 9px;
  padding: 56px 0 58px;
}
.frameworkList{
  text-align: center;
  margin-top:2%;
  padding-top: 2%;
  padding-bottom: 2%;
  /* margin: 0 12.5% 3% 12.5%;
  padding: 44px 0 48px 0; */
}
.frameworkList > img{
  width: 100%;
}
.currencyLittleTit{
  font-size: 26px;
  text-align: left;
  color: #101010;
  padding-left: 5%;
}
/*产品特点*/
.proChara{
  text-align: left;
  width: 97%;
  margin-left: 5.5%;
}
.proChara ul{
  width: 16.666%;
  display: block;
  float: left;
}
.proChara ul:first-child{
  width: 17%;
}
.proChara ul:nth-child(2){
  width: 19%;
}
.proChara ul:nth-child(3){
  width: 15.6%;
}
.proChara ul:nth-child(6){
  width: 14%;
}
.proChara ul:nth-child(5){
  width: 14.1%;
}

.proChara li{
  list-style: disc;
  line-height: 24px;
  padding-right: 16%;
  text-align: justify;
  color: #888;
}
/*产品功能*/
.proFunctionBox{
  background-color: #fff;
  width: 33.333%;
  float: left;
  padding-top: 3%;
  padding-bottom: 2%;
}
.proFunctionBox img{
  width: 84px;
  height: 80px;
}
.proFunctionTitle{
  font-size: 24px;
  color: #101010;
  line-height: 53px;
  text-align: center;
}
.proFunctionDesc p{
  font-size: 16px;
  color: #888;
  line-height: 24px;
  height: 120px;
  padding: 5% 20%;
  text-align: justify;
  white-space: break-spaces;
}
.proFunctionImg{
  text-align: center;
}

@media screen and (min-width: 1000px){
  
}
@media screen and (min-width: 1450px){
  .proChara li{line-height: 30px;}
}
@media screen and (min-width: 1560px){
}
</style>